button,
input,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;

  color: inherit !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
li,
button {
  font-family: "Conthrax", sans-serif;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}