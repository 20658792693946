.carousel {
  max-width: 100%;

  display: flex;
  align-items: center;
  gap: 30px;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .carousel {
    position: absolute;
    left: 0;

    width: 100vw;
    max-width: 100vw;
  }
}
